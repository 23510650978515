/* h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(255, 255, 255, 1);
} */
.bn-onboard-custom {
  z-index: 1;
}

html {
  background-color: #1f1f1f;
}

#root {
  background: no-repeat url("./media/Icons/background.png");
  background-position: 40%;
  background-color: #1f1f1f;
  min-height: calc(100vh - 74px);
  background-size: contain;
  height: inherit;
}

.IAppWrapper {
  height: inherit;
  display: flex;
  flex-direction: column;
}

#root body {
  overflow: auto;
}

#root .MuiSelect-select {
  height: 0;
  padding: 12px 14px;
}

#root .formWr {
  background: #2c2c2c;
  border-radius: 20px;
  margin: 0 auto;
}

#root .MuiTab-root {
  color: #fff;
  font-weight: 700;
  background: transparent;
}

#root .MuiTabs-indicator {
  background-color: transparent;
}

#root .MuiButton-fullWidth.MuiButtonBase-root {
  background: #fdcb12;
  border: 1px solid #fdcb12;
  border-radius: 45px;
  color: #2c2c2c;
  -webkit-text-fill-color: #2c2c2c;
}

#root .MuiOutlinedInput-input {
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(253, 203, 18, 0.07);
  border-radius: 50px;
  color: white;
  font-size: 14px !important;
  padding: 12px 14px;
}

#root .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: transparent;
}

#root .MuiSelect-icon {
  color: #fff;
}

#root .Mui-disabled {
  color: #fff;
  opacity: 0.7;
  -webkit-text-fill-color: #fff;
}

#root .MuiFormControl-root fieldset {
  border: none;
}

#root .MuiFormLabel-filled {
  top: -11px;
  color: #ffffff;
}

#root .MuiInputLabel-animated {
  color: #ffffff;
  font-size: 14px;
  line-height: 14px;
}

#root .MuiInputLabel-animated.Mui-focused {
  color: #ffffff;
  top: -10px;
}

#root .tokenWr {
  width: 100%;
}

#root .MuiInputBase-fullWidth:before {
  border-bottom: 1px solid #fff;
  opacity: 0.7;
}

#root .amountField .MuiInputBase-formControl {
  padding-right: 0;
}

#root .MuiOutlinedInput-notchedOutline {
  border: none;
}

#root .amountField input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

#root .amountField button {
  height: 2.89rem;
  background: #fdcb12;
  border-radius: 0px 50px 50px 0px;
  color: #2c2c2c;
  -webkit-text-fill-color: #2c2c2c;
  border: none;
}

#root .filledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdcb12;
  padding: 4.8px 16px;
  margin-right: 8px;
  border: 1px solid #fdcb12;
  border-radius: 45px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #2c2c2c;
  min-height: 33px;
  cursor: pointer;
}

.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
  background-color: #fdcb12 !important;
}

#root .transparentButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  padding: 4.8px 16px;
  margin-right: 8px;
  border: 1px solid #fdcb12;
  border-radius: 45px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: white;
  min-height: 33px;
  cursor: pointer;
}

#root .transparentButton:hover {
  background: #fdcb12;
  color: #2c2c2c;
}

#root .filledButton:hover {
  opacity: 0.9;
}

#root .MuiFormGroup-root {
  color: #fff;
}

#root .MuiCheckbox-root {
  color: #fff;
}

#root .feeText {
  color: #fff;
}

html {
  height: -webkit-fill-available;
}

html body {
  background-color: #1f1f1f;
  height: inherit;
}

html body .MuiPopover-paper {
  background: #313131;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
}

html body .MuiPaper-root .Mui-selected {
  background-color: rgba(253, 203, 18, 0.2);
  color: #fff;
}

html body .Mui-selected:hover {
  background-color: rgba(253, 203, 18, 0.2) !important;
  color: #fff;
}

html body .MuiMenuItem-root.MuiMenuItem-gutters.MuiButtonBase-root {
  color: #fff;
}

.bn-onboard-custom.bn-onboard-modal.svelte-rntogh {
  background-color: rgba(0, 0, 0, 0.8);
}

.bn-onboard-custom.bn-onboard-modal-content {
  background: #2c2c2c;
  border-radius: 10px;
}

.bn-onboard-custom.bn-onboard-select-info-container,
.bn-branding,
.bn-onboard-custom.bn-onboard-modal-content-header-icon {
  display: none !important;
}

.bn-onboard-custom.bn-onboard-modal-content-header-heading {
  margin: 0 auto;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
}

.bn-onboard-custom.bn-onboard-prepare-description {
  color: white;
}

.bn-onboard-custom.bn-onboard-prepare-button-container {
  margin: 20px 0;
}

.bn-onboard-custom.bn-onboard-prepare-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdcb12;
  padding: 4.8px 16px;
  margin-right: 8px;
  border: 1px solid #fdcb12;
  border-radius: 45px;
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 12px;
  line-height: 12px;
  color: #2c2c2c;
  min-height: 33px;
  cursor: pointer;
}

.bn-onboard-custom.bn-onboard-prepare-button:hover {
  opacity: 0.9;
  background: #fdcb12;
}

.bn-onboard-custom.bn-onboard-modal-content-close svg {
  fill: #ffffff;
  width: 12px;
  height: 11px;
}

.bn-onboard-custom.bn-onboard-select-description {
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.bn-onboard-custom.bn-onboard-icon-button.bn-onboard-selected-wallet {
  background: #313131;
  box-sizing: border-box;
  border-radius: 10px;
}

.bn-onboard-custom.bn-onboard-icon-button.bn-onboard-selected-wallet span,
.bn-onboard-custom.bn-onboard-modal-select-wallets span {
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 1px;
  color: #ffffff;
}

.bn-onboard-custom.bn-onboard-modal-select-wallets {
  background: #313131;
  border: 1px solid rgba(145, 148, 159, 0.2);
  box-sizing: border-box;
  box-shadow: 0px 0px 20px rgb(0 0 0 / 11%);
  border-radius: 10px;
}

/* .bn-onboard-custom.bn-onboard-modal {
  font-family: 'Montserrat';
  border-radius: 5px;
}

.bn-onboard-custom.bn-onboard-modal-content {
  background: #424242 !important;
}

.bn-onboard-prepare-button {
  border-color: #FFE500 !important;
  color: #FFE500 !important;
}

.bn-onboard-custom.bn-onboard-select-info-container > span {
  color: #FFE500 !important;
} */
/* Chrome, Safari, Edge, Opera */
/* input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: textfield;
} */

.errorBoundaryWr {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorBoundary {
  margin: 0 auto;
  background: #2C2C2C;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 20px;
  max-width: 500px;
  padding: 24px;
}

.errorBoundaryIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.errorBoundary > *  {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.errorBoundaryTitle {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  margin-bottom: 20px;
}

.errorBoundaryText {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.errorBoundaryBtnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.errorBoundaryBtnGroup button {
  min-width: 200px;
}
